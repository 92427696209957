//Header scoroll
$(window).scroll(function() {
    var scroll = $(window).scrollTop();
     //>=, not <=
    if (scroll >= 500) {
        //clearHeader, not clearheader - caps H
        $(".header_wrapper").addClass("pinned");
    }else{
      $(".header_wrapper").removeClass("pinned");
    }

    if (document.querySelector('.flavor_sidebar_button')) {
      if ($(document).scrollTop() + 400 >= $('.flavor_wrapper').offset().top) {
        console.log($('.flavor_wrapper').offset().top)
        $('.flavor_sidebar_button').addClass('visible');
      } else {
        $('.flavor_sidebar_button').removeClass('visible');
      }
    }
}); //missing );

/*Open Menu*/
$(".menu-button").on('click', function() {
  if($(this).hasClass('is-active')){
      $("html").css('overflow-y','scroll');
      $(this).removeClass('is-active');
      $('.header_center').removeClass('is-active');
      // $('.mobile_menu').removeClass('openmenu');
    }
    else{
      $(this).addClass('is-active');
      jQuery("html").css('overflow-y','hidden');
      $('.header_center').addClass('is-active');
      // $('.mobile_menu').addClass('openmenu');
  }
});




/*Hover Menu - Add Class to all except hover item*/
// $(document).ready(function() {
//   $('#menu-main-menu li a').hover(function() {
//     $('#menu-main-menu li a').not($(this)).addClass('not-hovered');
//   }, function() {
//     $('#menu-main-menu li a').removeClass('not-hovered');
//   });
// });

/*Custom Tabs*/
$("#custom_tabs .tabs p").on('click', function () {
  var tab_open;
  var content_open;
  if($(this).hasClass('active')){

  }else{
    $("#custom_tabs .tabs p").removeClass('active');
    $(this).addClass('active');
    tab_open=$(this).attr("data-tab");
  }
  $( ".tab_content .content" ).each(function( index ) {
      content_open=$(this).attr("data-tab");
      if(content_open == tab_open){
        $( ".tab_content .content" ).removeClass('active');
        $(this).addClass('active');
      }
  });
});


/*Add animation on page load to h1*/
$(document).ready(function () {
  setTimeout(function () {
    $('h1').addClass('is-visible');
  }, 200);
});


/* Default slider swipper */
//import Swiper, { Navigation , Pagination } from 'swiper';

if($("#icecream").length){
    var mySwiper= new Swiper(".icecream_swipe", {
        slidesPerView:4,
        centeredSlides: true,
        loop: false,
        grabCursor: true,
        direction: 'horizontal',
        speed: 1200,
        spaceBetween: 50,
        initialSlide:2,
        breakpoints: {
        1281: {
          slidesPerView: 3.5
        },
        1025: {
          slidesPerView: 3.5
        },
        769: {
          slidesPerView: 2.5
        },
        451: {
          slidesPerView: 1.5
        },
        0: {
          slidesPerView: 1.5
        },
      },
    });
    function updateClasses() {
        var activeSlideIndex = mySwiper.activeIndex;
        var totalSlides = mySwiper.slides.length;

        // Remove the classes from all slides first
        for (var i = 0; i < totalSlides; i++) {
            mySwiper.slides[i].classList.remove('after-next');
            mySwiper.slides[i].classList.remove('before-prev');
        }

        // Calculate the indices of slides after swiper-slide-next and before swiper-slide-prev
        var nextSlideIndex = (activeSlideIndex + 2) % totalSlides;
        var prevSlideIndex = (activeSlideIndex - 2 + totalSlides) % totalSlides;

        // Add classes to the slides
        mySwiper.slides[nextSlideIndex].classList.add('after-next');
        mySwiper.slides[prevSlideIndex].classList.add('before-prev');
    }

    // Initial update of classes
    updateClasses();

    // Listen for slide change events
    mySwiper.on('slideChange', function () {
        // Update the classes when the slide changes
        updateClasses();
    });
};



    $( ".menu li" ).each(function( index ) {
      $(this).append("<svg viewBox='0 0 500 150' preserveAspectRatio='none'><path fill='none' d='M325,18C228.7-8.3,118.5,8.3,78,21C22.4,38.4,4.6,54.6,5.6,77.6c1.4,32.4,52.2,54,142.6,63.7 c66.2,7.1,212.2,7.5,273.5-8.3c64.4-16.6,104.3-57.6,33.8-98.2C386.7-4.9,179.4-1.4,126.3,20.7'/></svg>")
    });

    /*change background*/
    $(window).on('scroll', function() {
        $('.flavors').each(function() {
            if ($(this).hasClass('aos-animate')) {
                var color = $(this).attr('data-color');
                $('#flavors').css('background-color', color);
            }
        });
    });

    if ($(".flavor_sidebar p")) {
      $(".flavor_sidebar p").on('click', function () {
          var clickto=$(this).attr('data-id');
          $('html, body').animate({
              scrollTop: $('#'+clickto).offset().top - 100 }, 2000);
      });
    }
    
document.addEventListener('DOMContentLoaded', function () {
  var cardSwiper = new Swiper(".swiper.cards_swiper_container", {
    direction: 'vertical',
    effect: 'cards',
    grabCursor: true,
    loop: true,
    navigation: {
      nextEl: '.card-swiper-button-next',
      prevEl: '.card-swiper-button-prev',
    },
  });
});
