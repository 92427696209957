import './modules/script';
import './modules/map/mapalex';


import AOS from 'aos';
// ..
AOS.init();
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});
AOS.init({
  disable: function() {
    var maxWidth = 767;
    return window.innerWidth < maxWidth;
  }
});
//
// import LocomotiveScroll from 'locomotive-scroll';
//
// const scroll = new LocomotiveScroll({
//     el: document.querySelector('[data-scroll-container]'),
//     smooth: true,
//     getSpeed:true,
//     multiplier:0.7
// });



 // $( document ).ready(function() {
 //
 //   new ResizeObserver(() => scroll.update()).observe(document.querySelector("[data-scroll-container]"));
 //    //sticky header
 //    if( $('body').hasClass('page-template-default')){
 //
 //    }else{
 //      scroll.on('scroll',(instance)=>{
 //              if(instance.scroll.y > 300){
 //                  $('.header_wrapper').addClass('pinned');
 //              }else{
 //                $('.header_wrapper').removeClass('pinned');
 //              }
 //      });
 //    }
 //
 // });

if (window.innerWidth > 1024) {
 const cursor = document.getElementById('cursor');
 const circle = document.querySelector('.cursor__circle');

 const updateCursor = (e) => {
     cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
 };

 document.addEventListener('mousemove', updateCursor);

 let prevX = 0;
 let prevY = 0;

 const updateCircleSize = () => {
     const x = event.clientX;
     const y = event.clientY;
     const speed = Math.sqrt((x - prevX) ** 2 + (y - prevY) ** 2);
     const scale = 1 + speed * 0.002; // Adjust the multiplier to control the jiggle intensity

     circle.style.transform = `translate(-50%, -50%) scale(${scale})`;

     prevX = x;
     prevY = y;
 };

  $('.cards_swiper_container').on('mouseenter', function() {
    $("#cursor .cursor__circle").addClass('enabled_arrows');
  });
  $('.cards_swiper_container').on('mouseleave', function() {
    $("#cursor .cursor__circle").removeClass('enabled_arrows');
  });
//  document.addEventListener('mousemove', updateCircleSize);
}

const flavorSidebarButton = document.querySelector(".flavor_sidebar_button")
if (flavorSidebarButton) {
  flavorSidebarButton.addEventListener("click", () => {
    document.querySelector(".flavor_sidebar").classList.toggle("is-active")
  })
}
